import React from 'react';
import Post from '../components/post';
import EvenFinancialWidget from '../components/EvenFinancialWidget';
import LazyLoadImage from '../components/LazyLoadImage';


const MedicalBillsPage = () => {
    const article = {
        id: '41e86f7b-a52c-527a-a124-4b67f2c26aa9',
        title: '18 Simple Ways to Minimize (or Erase) Scary Medical Bills',
        slug: '/medical-bills/',
        date: '2018-04-11T21:43:18.000Z',
        modified: '2021-11-01T19:40:27.000Z',
        excerpt: 'Take action to lower or erase the amount you pay for medical bills. With these 18 tips you have the knowledge to do what it takes to get your bills reduced or erased and pay the lowest amount possible.',
        featured_image: {
            source_url: '/media/medical-bills.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 20,
        content: (
            <>
                <p>In his 2009 State of the Union address, President Obama said a bankruptcy due to medical bills happens every 30 seconds in America.</p>
                <p>That&rsquo;s 1 million bankruptcies a year.</p>
                <p>Those numbers were confirmed in 2015 when the Kaiser Family Foundation found that 26% of Americans aged 18–64 struggle with paying medical bills.</p>
                <p>According to census data, that&rsquo;s 52 million adults.</p>
                <p>Medical bills are scary since you often don&rsquo;t expect them or budget for them.</p>
                <p>I&rsquo;m betting most of the million people who claim bankruptcy due to medical expenses every year probably aren&rsquo;t aware of one closely guarded fact about hospital bills:</p>
                <p>The bill you receive is not set in stone.</p>
                <p>Think of it more as a quote you can negotiate.</p>
                <p>The 52 million adults struggling with medical debt probably aren&rsquo;t aware of the forms of financial assistance offered by hospitals.</p>
                <p>They probably have no idea about the tactics a person can apply to bring the price down or even get a loan forgiven.</p>
                <p>There are also many government and social programs designed to support those who might be overwhelmed by medical debt.</p>
                <p>Haggling with your hospital or dickering with your doctor to lower your medical bills might not seem very dignified.</p>
                <p>It&rsquo;s still worthwhile to try to get the costs reduced or eliminated.</p>
                <p>
                    I can confirm from my own family&rsquo;s experience, medical costs for American families are
                    {' '}
                    <a href="https://www.cnbc.com/2017/06/23/heres-how-much-the-average-american-spends-on-health-care.html">easily averaging around $10,000 a year per person</a>
                    .
                </p>
                <p>I&rsquo;ve also observed a trend towards employers trimming benefits and shifting expenses to employees.</p>
                <p><LazyLoadImage src="/media/how-u-s-wage-increase-compares-to-select-medical-cost-increases-1.png" alt="How U.S. Wage Increase Compares to Select Medical Cost Increases" /></p>
                <p>If you&rsquo;ve got big medical bills and not enough money to afford them, it&rsquo;s time to launch your own personal healthcare reform.</p>
                <p>Use the 18 tactics offered below for negotiating, minimizing, and erasing medical bills and pay only a fraction (or not even a penny!) of your healthcare expenses.</p>
                <h2 id="hospital-debt">Hospital Debt</h2>
                <h3>What happens when you can&rsquo;t pay your medical bills?</h3>
                <p>Medical debt occurs when you can&rsquo;t pay your out-of-pocket medical bills on time.</p>
                <p>Under some conditions, a patient doesn&rsquo;t receive an invoice, like when a person is hospitalized for a workplace injury and the entire bill can be paid via a worker&rsquo;s compensation claim.</p>
                <p>For everyone else, there&rsquo;s going to be a bill and you&rsquo;re probably going to have to pay at least some of it.</p>
                <p>
                    <strong>Debt happens. </strong>
                    We take on debt as part of our normal, everyday lives, so having medical debt is not the end of the world.
                </p>
                <p>As long as you can come up with solutions to manage it and pay it down, having debt can actually have benefits like strengthening your credit score.</p>
                <p>
                    Medical expenses are often unexpected, which
                    {' '}
                    <a href="https://www.debtconsolidation.com/4-keys-out-of-debt/">makes them much more difficult to manage</a>
                    {' '}
                    than, for example, a car loan.
                </p>
                <p>No one plans to get hurt in a car accident, or to need a ride in an ambulance, or to suddenly get sick.</p>
                <p>But receiving immediate medical care and treatment and getting well should be your top priorities.</p>
                <p>When these things happen, you can find yourself looking at a big bill you never budgeted for.</p>
                <p>The costs of healthcare are huge, and the United States spends more per person on healthcare than any other country in the world.</p>
                <p>Having an emergency fund is always recommended, but how many of us actually set aside a decent portion of our paycheck in preparation for a financial crisis?</p>
                <p>Like any form of debt, if we&rsquo;re not able to manage medical debt properly, it starts to affect our credit.</p>
                <p>
                    <strong>Late payments sting. </strong>
                    When you start submitting payments late, you get charged penalties and the debt can grow rather than shrink.
                </p>
                <p>Your credit score also gets dinged every time you submit a payment that&rsquo;s past-due.</p>
                <p>
                    <strong>Next stop…collections.</strong>
                    {' '}
                    When you can&rsquo;t pay your hospital bill on time, eventually the debt will be sent to a collections agency.
                </p>
                <p>You&rsquo;ll be hounded by never-ending calls from collections agents.</p>
                <p>Ignoring them will not make them go away.</p>
                <p>
                    <strong>Black marks on your credit score. </strong>
                    When
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-collection/">your debt is sent to a collections agency</a>
                    , your credit score will be hurt even more.
                </p>
                <p>You&rsquo;ll start to find it difficult to access credit—like mortgages, home equity loans, or personal loans—without being charged steep interest rates.</p>
                <p>If you choose to ignore the collections agencies you can eventually be sued, and your wages can be garnished (that&rsquo;s when a little bit is taken off of every paycheck).</p>
                <p>
                    <strong>Bankruptcy becomes the only option. </strong>
                    Ultimately, if you are unable to pay off your medical debts, your only choice might be declaring bankruptcy.
                </p>
                <p>By declaring bankruptcy, you are no longer responsible for paying off your creditors.</p>
                <p>You won&rsquo;t owe the hospital or collections agencies anymore, but your credit rating will be completely trashed for many years.</p>
                <p><LazyLoadImage src="/media/number-of-personal-bankruptcies-declined-from-2009-2016-2.png" alt="Number of Personal Bankruptcies Declined from 2009-2016" /></p>
                <p>To avoid things spiraling out of control, it&rsquo;s important to get a good grip on your medical bills right from the start.</p>
                <p>That could involve going back to the healthcare provider to personally discuss your bill and negotiate a way to reduce what you pay.</p>
                <h2 id="health-care-lingo">Health Care Lingo</h2>
                <h3>Get to know the terms so you can negotiate on your own behalf</h3>
                <p>One important thing to do before you start trying to talk to hospitals and other healthcare providers about your bills is to become familiar with their language.</p>
                <p>Not knowing these terms could lead to thousands of dollars of unnecessary medical charges.</p>
                <p>
                    <strong>Co-insurance is your cut of the medical bill.</strong>
                    {' '}
                    The percentage you are on the hook for when a bill comes is called co-insurance.
                </p>
                <p>If your co-insurance rate is 30% for outpatient surgeries, for a $10,000 procedure your insurer will cover $7,000 (70%).</p>
                <p>You&rsquo;ll be responsible for $3,000 (30%) as your co-insurance.</p>
                <p>
                    <strong>Co-pay is the price tag for user fees. </strong>
                    Similarly, the term co-pay refers to charges paid by the healthcare consumer rather than their insurance company.
                </p>
                <p>Rather than a percentage, the co-pay amount is usually a flat fee.</p>
                <p>For example, your co-pay rules could require you to cough up $25 for every doctor visit.</p>
                <p>
                    <strong>Deductible is the amount you pay before your insurance kicks in.</strong>
                    {' '}
                    Your health insurance deductible is the out-of-pocket costs you cover prior to the insurance company payments.
                </p>
                <p>If you have a $1,000 deductible, for a $10,000 hospital bill you&rsquo;d pay the first $1,000, and the insurer would pay the remaining $9,000.</p>
                <p>
                    <strong>It matters whether your provider is &quot;in network&quot; or &quot;out of network.&quot; </strong>
                    Health insurance plans often only cover healthcare providers within a specific network.
                </p>
                <p>This is a way for insurance companies to save money by purchasing group discounts.</p>
                <p>
                    <strong>Out-of-pocket maximum is the most you&rsquo;ll ever pay. </strong>
                    Healthcare insurance plans usually identify an out-of-pocket maximum amount.
                </p>
                <p>When you add up all deductibles, co-payments, co-insurance, and other costs, your portion cannot exceed the out-of-pocket max.</p>
                <p>The health insurance provider pays for everything above the max amount.</p>
                <p>For example, if your policy has an out-of-pocket max of $30,000 for one year, anything over $30,000 is covered by insurance during that plan year.</p>
                <h2 id="hospital-financial-assistance">Hospital Financial Assistance</h2>
                <h3>Hospitals don&rsquo;t want you to go bankrupt</h3>
                <p>If you are overwhelmed by medical debt to the point of bankruptcy, the hospitals you owe money to are in danger of not getting paid at all.</p>
                <p>
                    <strong>Something is better than nothing.</strong>
                    {' '}
                    Rather than losing the entire revenue from your hospital bill, most institutions will be willing to explore alternatives.
                </p>
                <p>If you are uninsured, or if you are insured but owe a significant amount of money, hospitals will often be open to reconsidering your invoice—especially if you can demonstrate you have a lower income.</p>
                <p>Here are the first four ways to minimize or erase scary medical bills.</p>
                <p>They all involve working with your hospital to come up with a manageable payment arrangement.</p>
                <p><LazyLoadImage src="/media/americans-medical-debt-from-2011-2016-3.png" alt="Americans Medical Debt from 2011-2016" /></p>
                <p>
                    <strong>1. Reach out to the right person. </strong>
                    The very first key to getting your bills erased or minimized is to talk to the right person.
                </p>
                <p>You need to get in touch with the hospital or medical care provider&rsquo;s billing department.</p>
                <p>It&rsquo;s not going to be up to a doctor or nurse to arrange your payment.</p>
                <p>The billing department is where your bills and payments are processed, and if the price is going to go down (or go away!), it will be through them.</p>
                <p>
                    <strong>A good bedside manner. </strong>
                    Here&rsquo;s one positive: The person you end up speaking to will most likely have been through this scenario many times, so they&rsquo;ll probably do a great job of keeping your dignity intact.
                </p>
                <p>
                    <strong>2. Ask for a bill reduction. </strong>
                    If you don&rsquo;t have insurance at all, your bill will likely be reduced automatically.
                </p>
                <p>Uninsured people with lower incomes should see more drastic reductions than people with moderate or higher incomes.</p>
                <p>
                    <strong>It&rsquo;s just a phone call away. </strong>
                    Pick up the phone or visit the billing department. Tell them you can&rsquo;t pay the bill you received due to financial hardship.
                </p>
                <p>
                    We&rsquo;ve seen examples of bills being reduced to 10% of the original amount.
                    {' '}
                    <em>A $15,000 hospital bill can be slashed into a $1,500</em>
                    <em> bill with just a phone call.</em>
                </p>
                <p>
                    <strong>3. Request bill forgiveness to erase the invoice entirely. </strong>
                    More rarely, usually in cases of dire financial need, hospitals will completely forgive a debt.
                </p>
                <p>Total forgiveness means they essentially rip up your bill.</p>
                <p>You&rsquo;re not on the hook for a penny.</p>
                <p>Someone can go from pending financial doom to complete freedom from medical bills with just a phone call.</p>
                <p>
                    <strong>4. Negotiate a 0% repayment plan. </strong>
                    The most likely form of assistance you&rsquo;ll get from hospitals is a no-interest repayment plan.
                </p>
                <p>
                    <strong>All incomes can do it. </strong>
                    No matter what your income level is, you can enroll in a 0% interest repayment plan.
                </p>
                <p>Sometimes this solution can be combined with bill reduction, where you pay a lower amount over time.</p>
                <p>Even if your bill is not reduced, it will be easier to chop up the full amount in installments over time rather than having to cough up the full amount upfront.</p>
                <p>Often, the length of time is two years from the billing date to get your bill cleared.</p>
                <p>It doesn&rsquo;t hurt to ask for a longer repayment period.</p>
                <p>Since it&rsquo;s a 0% interest loan, there is no rush to pay it off.</p>
                <h2 id="negotiate">Negotiate</h2>
                <h3>Use these tips and tricks to keep your bill down</h3>
                <p>Rather than experiencing sticker shock when you receive a ridiculously high medical bill, you can be proactive and do what you can to reduce the balance you owe.</p>
                <p>There are a number of negotiation tricks people have used successfully to lower their hospital bill.</p>
                <p>
                    <strong>Keep calm and negotiate on. </strong>
                    When you&rsquo;re negotiating with your healthcare provider, it&rsquo;s important to remain firm but polite.
                </p>
                <p>Be nice, but don&rsquo;t give up.</p>
                <p>Be persistent, but don&rsquo;t be rude.</p>
                <p>Keep your cool and remember you want the person on the other end of the line to help you.</p>
                <p>Here are the next nine tips for minimizing (or erasing) scary medical bills.</p>
                <p>They all involve negotiation strategies you can use to lower the bottom line for medical expenses.</p>
                <p>
                    <strong>5. Compare prices with other providers. </strong>
                    The first step is to make yourself aware of the fair market price for the medical services or procedures you&rsquo;re paying for.
                </p>
                <p>
                    <strong>Shop around first. </strong>
                    You wouldn&rsquo;t buy a car without comparing the price to similar models, so why wouldn&rsquo;t you comparison shop for expensive medical procedures?
                </p>
                <p>Looking around to see the various prices offered by other doctors for your care gives you the opportunity to choose the provider that is charging the lowest rate.</p>
                <p>When you&rsquo;re armed with market data, you also have a great negotiation tool.</p>
                <p><LazyLoadImage src="/media/medical-costs-from-2007-2017-4.png" alt="Medical Costs from 2007-2017" /></p>
                <p>If you&rsquo;re insured, often your insurance company will have a cost comparison chart for providers in its network.</p>
                <p>You can also find the cost of medical procedures in your area by checking websites like HealthcareBluebook.com.</p>
                <p>
                    <strong>When in doubt, ask Google. </strong>
                    Just Googling &quot;Medicare fee schedule&quot; for the state you live in will give you an appreciation of the reasonable costs associated with your treatment.
                </p>
                <p>
                    <strong>6. Negotiate upfront before treatment.</strong>
                    {' '}
                    The best way to get your healthcare provider to give you a lower fee is to ask upfront before treatment happens.
                </p>
                <p>Always start by asking about the particular procedure you&rsquo;ll be billed for and the billing codes involved.</p>
                <p>Sometimes providers will even have an option to look up prices on their phone menu.</p>
                <p>
                    <strong>Name your price. </strong>
                    When you&rsquo;ve done your homework and researched the best prices offered for your treatment, you can request your provider to match (or beat) the best price out there.
                </p>
                <p>If you can prove your provider is charging more than other providers in your area for the same treatment, the billing department will often match the competitor&rsquo;s price.</p>
                <p>
                    <strong>Vote with your feet.</strong>
                    {' '}
                    In the situation where your provider will not budge on the price even though you&rsquo;ve shown them lower rates, it might be time to go to the lower-priced option.
                </p>
                <p>
                    <strong>7. Pay the full amount with cash upfront to lower bills.</strong>
                    {' '}
                    Another negotiation tactic you can apply to medical bills is offering to pay the full amount in cash.
                </p>
                <p>Ask the billing department if it has a special rate for cash payments.</p>
                <p>
                    <strong>Money talks. </strong>
                    Especially if you&rsquo;re not insured, paying with cash can save you from having to pay the full amount.
                </p>
                <p>You can probably get an even better price than your insurance company gets if you offer to pay with cash.</p>
                <p>For example, if you have a $15,000 hospital bill, ask if the billing department would take off 30% ($4,500) if you paid upfront in cash.</p>
                <p>It might only offer a 5% or 10% discount for self-paying with cash.</p>
                <p>With a $15,000 bill, a 10% reduction would save you $1,500.</p>
                <p>The self-pay discount will be more likely if you&rsquo;re getting care from an independent healthcare center rather than a big hospital.</p>
                <p>
                    <strong>8. Use an FSA or HSA to pay cash. </strong>
                    Some employers give their workers the opportunity to set up a Flexible Spending Account (FSA) to set aside money for medical bills and avoid payroll taxes.
                </p>
                <p>
                    Similarly, a Health
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=medical-bills&amp;sub2=savings" rel="noopener noreferrer" target="_blank">Savings</a>
                    {' '}
                    Account (HSA) is specifically designed to save an untaxed portion of an employee&rsquo;s earnings for medical bills.
                </p>
                <p>Funds from an FSA or HSA can be accessed as cash and offered upfront to avail of a discount from the healthcare provider who is treating you.</p>
                <p>
                    <strong>9. Check the details and look for errors. </strong>
                    Then there&rsquo;s the chance the jaw-dropping total at the bottom of your hospital invoice is the result of a mistake.
                </p>
                <p>Go over your bill with a fine-toothed comb to make sure you&rsquo;re only getting charged for what you really owe.</p>
                <p>Sometimes you get charged for services or procedures that were never even rendered.</p>
                <p><LazyLoadImage src="/media/data-regarding-medical-billing-errors-5.png" alt="Data Regarding Medical-Billing Errors" /></p>
                <p>
                    <strong>Double billing is when you&rsquo;re charged for the same procedure twice. </strong>
                    Check the reference number on your bill and make sure you didn&rsquo;t already pay.
                </p>
                <p>
                    <strong>Only pay for what you got. </strong>
                    When medical services fail, you shouldn&rsquo;t have to foot the bill.
                </p>
                <p>For example, if you were given two blood tests because the first one was erroneous or inconclusive, you should only pay for one.</p>
                <p>
                    <strong>Confirm the medication prices on your bill.</strong>
                    {' '}
                    By cross-checking the prices for the medications you received, you can pick up on any errors made when entering codes.
                </p>
                <p>
                    <strong>10. Seek a settlement with the hospital.</strong>
                    {' '}
                    If your medical bill payments are overdue and you&rsquo;re not confident you&rsquo;ll be able to stay on top of them, you can seek a settlement.
                </p>
                <p>The hospital doesn&rsquo;t want you to fail to pay your debt.</p>
                <p>If it&rsquo;s looking like you won&rsquo;t be able to pay the total bill, you can ask if they will accept a portion of the full amount.</p>
                <p>For example, if you can show the billing department how your $15,000 debt threatens to plunge you into bankruptcy, it might accept 30% ($4,500) from you and call it even.</p>
                <p>
                    <strong>11. Request an outpatient procedure.</strong>
                    {' '}
                    Sometimes, a procedure scheduled as inpatient care can be changed to an outpatient format.
                </p>
                <p>
                    <strong>Recover at home rather than the hospital. </strong>
                    Since you don&rsquo;t spend a night in a hospital room, you&rsquo;re charged significantly less for outpatient care.
                </p>
                <p>There are often outpatient centers for the same procedures conducted in hospitals as inpatient care.</p>
                <p>
                    <strong>Save thousands on procedures. </strong>
                    For example, procedures like knee surgeries can be switched to outpatient arrangements, with
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=medical-bills&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    up to $5,000.
                </p>
                <p>There might be reasons your doctor wants to monitor you overnight, in which case going outpatient will not be an option, but it is worth asking.</p>
                <p>
                    <strong>12. Ask if you qualify for any discounts. </strong>
                    No matter what you&rsquo;re spending money on, it never hurts to ask if there are any discounts you qualify for.
                </p>
                <p>Many times, you can get a discount you would have never received if you hadn&rsquo;t asked about it.</p>
                <p>
                    <strong>Go paperless and get a discount.</strong>
                    {' '}
                    For example, some networks will give you an automatic 10% discount for paying your bill over the phone or online.
                </p>
                <p>They&rsquo;re not going to advertise the discounts or offer them to you upfront.</p>
                <p>You&rsquo;re usually going to have to ask and you might be pleasantly surprised to discover there are discounts available to you.</p>
                <p>
                    <strong>13. Keep great records.</strong>
                    {' '}
                    If you&rsquo;re going to negotiate your medical bill, you will have more success if you have as much information as possible about your care.
                </p>
                <p>Make sure you have a copy of your medical records.</p>
                <p>
                    <strong>Record all the details, you might need them. </strong>
                    Take note of and document absolutely everything during and after your treatment.
                </p>
                <p>Keep all your bills and insurance papers organized and make records of the phone calls you make.</p>
                <p>Record the names of staff who you were talking to, as well as the location and a call reference number.</p>
                <p>Follow up in a few weeks with the people who you were discussing your bills with and discuss any opportunities to potentially renegotiate.</p>
                <h2 id="government-and-social-programs">Government and Social Programs</h2>
                <p>One out of every three dollars being chased by collection agencies is for medical bills.</p>
                <p>The alarming trend of medical bills ruining people financially has not gone unnoticed by the government, the healthcare industry, or charitable and nonprofit organizations.</p>
                <p>Fortunately, there are multiple resources available to help people get the healthcare they need without literally going bankrupt.</p>
                <p>
                    <strong>14. Access HRSA to make healthcare free or affordable. </strong>
                    The federal government&rsquo;s Health Resources and Services Administration (HRSA) program
                    {' '}
                    <a href="https://www.hrsa.gov/">helps economically challenged people to pay for the healthcare they need.</a>
                </p>
                <p>HRSA is an agency of the U.S. Department of Health and Human Services.</p>
                <p>Healthcare providers receive grants to support the healthcare costs for people who are uninsured, living with HIV/AIDS, pregnant women, and mothers with children.</p>
                <p>23 million people receive direct healthcare through the support of $10 billion a year in HRSA funding.</p>
                <p>16 million of those people received their care at an HRSA health center program, which includes mobile clinics for low-income neighborhoods.</p>
                <p>Ask the hospital or health center where you are receiving care if the institution receives HRSA funding and if you qualify as a recipient.</p>
                <p>There&rsquo;s a good chance it does receive grants to support care for low-income individuals or members of vulnerable groups, especially if it&rsquo;s an HRSA health center program.</p>
                <p>
                    <strong>Free treatment is available. </strong>
                    Whatever your medical needs are, there are usually healthcare providers who can treat you at no cost.
                    <LazyLoadImage src="/media/americans-are-denying-themselves-treatment-due-to-medical-costs-6.png" alt="Americans are Denying Themselves Treatment Due to Medical Costs" />
                </p>
                <p>Free services are provided through both public and private healthcare agencies.</p>
                <p>These providers will either charge you nothing or make you pay a minor fee for services.</p>
                <p>For example, for eligible patients, the provider might offer free checkups and prescriptions.</p>
                <p>
                    <strong>15. Find a medical debt forgiveness charity focused on your condition. </strong>
                    There are many charities operating with the mandate to help people access healthcare.
                </p>
                <p>
                    <strong>Funding life-changing medical procedures. </strong>
                    For example, Operation Smile is an organization entirely
                    {' '}
                    <a href="https://www.operationsmile.org/">committed to funding surgeries for cleft lip or palate problems.</a>
                </p>
                <p>There are several charitable organizations focused on cancer treatment.</p>
                <p>
                    <strong>Cancer can be expensive. </strong>
                    For example, the Livestrong Foundation
                    {' '}
                    <a href="https://www.livestrong.org/tags/financial-assistance">provides healthcare assistance for uninsured people facing the costs of cancer.</a>
                </p>
                <p>The expensive and long-term treatments people receive for cancer can cause financial ruin.</p>
                <p>These charities help people and families who are going through cancer to get the medication and treatment they require without having to deal with huge bills.</p>
                <p>
                    <strong>May your medical debt Rest in Peace.</strong>
                    {' '}
                    The registered charity known as RIP Medical Debt &quot;locates, buys, and forgives unpaid and unpayable medical debt for Americans, no strings attached.&quot;
                </p>
                <p>
                    A couple of years ago, the political comedian
                    {' '}
                    <a href="https://ripmedicaldebt.org/the-miracle-of-debt-forgiveness-on-hbos-last-week-tonight-with-john-oliver/">John Oliver bought off $15 million worth of medical debt via the same charity on his live TV show</a>
                    , wiping clean the outstanding medical debts of some 9,000 fortunate individuals.
                </p>
                <p>
                    <strong>Using the system to help those in need. </strong>
                    The charity takes advantage of the same system collections agencies use to buy bad debts at pennies on the dollar.
                </p>
                <p>With its buying power, RIP Medical Debt is able to purchase large numbers of debts, pay off the reduced amount, and bury them forever.</p>
                <p>
                    <strong>16. Consolidate your debts through a non-profit debt relief organization.</strong>
                    {' '}
                    If your maxed-out
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=medical-bills&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    are piling up interest and bankruptcy is looking like it could be a reality, you can call a non-profit debt relief service.
                </p>
                <p>It&rsquo;s different than a charity, in that the mandate of these companies is to specifically help people deal with debt (medical or otherwise).</p>
                <p>
                    <strong>Go-between for you and your creditors. </strong>
                    These organizations negotiate settlements with your creditors, then set up a special account for you to pay the agreed-upon amount.
                </p>
                <p>Part of these services regularly includes credit counseling and financial planning, so that the people they help don&rsquo;t find themselves in debt again.</p>
                <p>
                    <strong>Last ditch solution before bankruptcy. </strong>
                    Debt settlement is often accessed as an alternative to bankruptcy, with the goal of wiping the slate clean and re-establishing financial stability as soon as possible.
                </p>
                <h2 id="credit-options">Credit Options</h2>
                <h3>Manage medical bills with strategic forms of credit</h3>
                <p>Although it might feel illogical to deal with debt by taking on new forms of credit, there are a few options available to help make paying off medical bills more affordable.</p>
                <p>These options are not exactly the same as having your medical bill reduced or erased, but they certainly have the same function of decreasing the amount you have to pay.</p>
                <p>
                    <strong>17. Sign up for a credit card with a 0% APR intro rate. </strong>
                    There are many
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=medical-bills&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    out there with an introductory offer of 0% interest (APR) for a fixed temporary period.
                </p>
                <p>If you signed up for a card with a 0% APR intro period, you could pay your bill off over time rather than trying to pay it at once or paying a lot of interest while you pay it back.</p>
                <p>12-, 15-, or even 21-month no-interest periods are available depending on the card you select.</p>
                <p>Take the card and use it to pay off your unpaid hospital bills or interest-charging credit cards.</p>
                <p>
                    Then make a plan to pay it off completely within the interest-free period.
                    <LazyLoadImage src="/media/2018-americans-face-financial-issues-paying-medical-bills-7.png" alt="2018 Americans Face Financial Issues Paying Medical Bills" />
                </p>
                <p>
                    <strong>Significant savings are possible. </strong>
                    If you compare how much interest you would pay over that period with a regular credit card, you&rsquo;ll appreciate the savings.
                </p>
                <p>For example, if you paid a $12,000 debt in $1,000 monthly installments over a 12-month 0% period, you would pay no interest.</p>
                <p>If you paid the same debt at the same pace with a credit card charging the average APR (14.90%), you&rsquo;d pay an additional $975.47 in interest.</p>
                <p>One thing to watch out for is the regular APR the card will charge you once the introductory no-interest period is finished.</p>
                <p>
                    <strong>Beware of the full APR. </strong>
                    You might really believe you&rsquo;re going to pay the medical debt off during the intro period.
                </p>
                <p>If for some reason you don&rsquo;t pay it off in full during the introductory period, the full interest will kick in and you&rsquo;ll have to pay more.</p>
                <p>
                    <strong>18. Take out a loan to maintain your good credit score. </strong>
                    In addition to the threat of bankruptcy, having your medical debts sent to collections is bad for your credit score.
                </p>
                <p>According to the Federal Reserve, one out of every six credit reports has an unpaid medical debt.</p>
                <p>40% of those cases resulted in the person getting a lowered credit score.</p>
                <p>
                    Rather than letting your medical bills leave a black mark on your credit report, you can get a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=medical-bills&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    {' '}
                    to pay them off and then pay the loan back over time.
                </p>
                <p>Compared to credit cards, these loans typically have much lower interest rates.</p>
                <p>
                    <strong>Finding a loan is easy and convenient.</strong>
                    {' '}
                    There are lots of places a person can go to get an unsecured
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=medical-bills&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    {' '}
                    today.
                </p>
                <p>Only a few banks, like Wells Fargo, still offer personal loans today.</p>
                <p>
                    <a href="https://www.creditloan.com/personal-loans/8-best-places/">There are many online lenders picking up the slack</a>
                    .
                </p>
                <p style={{
                    fontSize: '1.71428571rem', lineHeight: '1.28571429em', fontWeight: 700, marginTop: '31.5917px', marginBottom: '5px', textAlign: 'center',
                }}
                >
                    See If You Qualify for a Personal&nbsp;Loan up&nbsp;to&nbsp;$250k
                </p>
                {' '}
                <EvenFinancialWidget url="https://embed.hifiona.com/ui/multi-product/index.html?partner=debtconsolidationembed&amp;access_token=628217c7-f4c2-4006-a2b1-3031b4c21272_8362ee6d-9497-4440-aaf2-8c6e5685f90b&amp;company_uuid=65d1a0bd-1cab-43c4-b755-2a6bff913a03&amp;productType=loan&amp;pw_fiona=true&amp;available_products=loan" slug="medical-bills" />
                <br />
                <p>For a lot of these loans, the application process is entirely online, and you can get approved the same day.</p>
                <p>For someone with a &quot;Good&quot; credit score (680–719), the average personal loan APR is 13.5–15.5%.</p>
                <p>
                    Let&rsquo;s say you took out a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=medical-bills&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    {' '}
                    for $10,000 to cover a hospital bill. You could pay it off in fixed monthly payments of $339.35 over 3 years.
                </p>
                <p>You&rsquo;d pay an extra $2,216.70 in interest, which is a small price to pay to avoid collections and bankruptcy.</p>
                <p>
                    <strong>Home equity loans let you borrow back the value of your house. </strong>
                    Many people share the experience of being &quot;cash-poor&quot; but &quot;house-rich.&quot;
                </p>
                <p>
                    They don&rsquo;t have tens of thousands of dollars in a bank account and might still be paying off
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=medical-bills&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    or living on social security.
                </p>
                <p>What they do have is a house they have been paying into every month for years.</p>
                <p>If you&rsquo;ve been paying into a home, you still own the money you&rsquo;ve paid in mortgage payments.</p>
                <p>Rather than selling your home to pay your medical bills, you can borrow back the paid-off portion of your house through a home equity loan.</p>
                <p>
                    <strong>Among the lowest interest rates available. </strong>
                    Home equity loans are secured loans, which means you&rsquo;re putting up your house as security in case you default.
                </p>
                <p>Since they are a fairly safe bet for lenders, relatively low-interest rates are available.</p>
                <p>Compared to the 14.90% average rate charged by credit cards, the 5% average charged for home equity loans is a great opportunity to reduce the cost of medical debt.</p>
                <p>If you had a $10,000 home equity loan at 5% and paid it off over 3 years, it would only cost you $789.52 in interest.</p>
                <p>With a credit card charging a 14.90% APR, you&rsquo;d pay $2,988.29 in interest over the same period.</p>
                <p>
                    <strong>A Home Equity Line of Credit is a revolving loan you can take or leave. </strong>
                    Similarly, you can choose a Home Equity Line of Credit (HELOC) to pay off your medical bills.
                </p>
                <p>The loan doesn&rsquo;t come in one lump sum, but rather becomes an available balance for you to draw from and pay back as you like.</p>
                <p>This could be useful for people who are going through long-term medical treatment (for example, diabetes) to cover future, as well as past costs.</p>
                <p>As long as you make minimum monthly payments, you are only charged interest on the outstanding balance (not the entire available amount).</p>
                <p>The amount available is based on how much equity you have in your home, and the credit line is secured by your house.</p>
                <p>
                    <strong>Don&rsquo;t lose your home! </strong>
                    Home equity loans and lines of credit are among the best tools for paying off large, unexpected expenses.
                </p>
                <p>You get the funds you need to get through a potential crisis, then pay it back over time at a reasonable interest rate.</p>
                <p>It&rsquo;s super-important not to take this kind of loan lightly.</p>
                <p>If you default, you can lose your house.</p>
                <p>You wouldn&rsquo;t be the first person to lose their home as a result of unpaid medical bills, so don&rsquo;t become a statistic.</p>
                <h3>You have the power to reduce or eliminate your medical bills</h3>
                <p>The hospitals don&rsquo;t want you to know, but the bill you get sent isn&rsquo;t a final invoice.</p>
                <p>It&rsquo;s more of a quote or proposal.</p>
                <p>Think of it as the opening bid in a negotiation.</p>
                <p>For those whose bills are more than they can handle, there are multiple options for reducing the total amount or even getting the full amount waived.</p>
                <p>There are also many organizations and programs available to help people struggling with medical debt.</p>
                <p>There are also a few borrowing options available for those who need to pay bills now and repay them over time.</p>
                <p>I&rsquo;ve seen medical debt sink families financially.</p>
                <p>I&rsquo;m so thankful my own family is healthy, and our annual out-of-pocket costs are still easily five-figures.</p>
                <p>It&rsquo;s so important families who are experiencing serious illness have the financial support they need to get back to health without the stress of pending bankruptcy.</p>
                <p>Have you successfully negotiated and reduced medical bills in the past?</p>
                <p>Any tips or techniques that you&rsquo;d like to share?</p>
                <p>Do you have large medical bills affecting your finances and would like us to help?</p>
                <p>Let us know in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default MedicalBillsPage;
